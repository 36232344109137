import React from 'react';

import Header from './components/header'
import Home from './components/home'

import Footer from '../_pages/components/footer'
import ScrollingWrapper from '../_pages/components/scrollingWrapper'
import 'bootstrap/dist/css/bootstrap.min.css'
import './style.scss'

const Page = () => {
    return (

      <ScrollingWrapper>
            <Header />
            <Home />

            <Footer />
      </ScrollingWrapper>

    );
}

export default Page
