import React from 'react'
import SectionTitle from './SectionTitle'
// images
import whoWeAre from '../../images/techImage2.png';
// import whoWeAre from '../images/mockup/Image.png'
import shape1 from '../images/shape/shape1.svg'
import ellipse from '../images/icon/ellipse.svg'

const whoWeAres = [
    {
        image: ellipse,
        title: 'Technology',
        text:
            'Our proprietary technology enables participants to interact, confidentially. This removes fear, shame and judgement from the learning process.',
    },
    {
        image: ellipse,
        title: 'Content',
        text:
            'Our content is shifting the conversation from tired topics to conversations that matter. Privilege. Power. White institutions.',
    },
    {
        image: ellipse,
        title: 'Domain Expertise',
        text:
            'Our global eco-system of expert consultants, practitioners, and academics deliver world-class support and results.',
    },
    {
        image: ellipse,
        title: 'Flexibility',
        text:
            'Flexible D&I learning experiences with deployment options that require no system integrations.',
    },
];


const WhoWeAre = () => {
    return (
        <div className="WhoWeAreArea" id="about">
            <div className="whoWeAreImg">
                <img src={whoWeAre} alt="" />
                <span className="shape1 poa zI-1 L-0 bounceAnimation">
                    <img src={shape1} alt="" />
                </span>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <SectionTitle
                            title="We Are Translator"
                            text="At Translator, D&I is in our DNA. We combine cutting-edge machine learning technology, best-in-class content, and expert consulting services to help you radically improve Diversity & Inclusion outcomes."
                        />
                    </div>
                    <div className="col-lg-6 offset-lg-6">
                        <ul className="whoWeAreContetnt">
                            {whoWeAres.map((who, i) => (
                                <li key={i}>
                                    <h4>
                                        <img src={who.image} alt="" />
                                        {who.title}
                                    </h4>
                                    <p>{who.text}</p>
                                </li>
                            ))}

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WhoWeAre
