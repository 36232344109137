import React from 'react';

import Header from './components/header'
import Hero from './components/hero'
import WhoWeAre from './components/whoWeAre'
import Platform from './components/platform'
import Featured from './components/featured'
// import FeaturedAwesome from './components/awesomeFeatured'
import News from './components/news'
import Partners from './components/partners'
import Contact from './components/contact'
import Footer from './components/footer'
import ScrollingWrapper from './components/scrollingWrapper'
import MobileMenu from '../components/MobileMenu'
import 'bootstrap/dist/css/bootstrap.min.css'
import './style.scss'

const Page = () => {
    return (

      <ScrollingWrapper>
            <Header />
            <MobileMenu />
            <Hero />
            <WhoWeAre />
            <Platform />
            <Featured />
            <News />
            <Partners />
            <Contact />
            <Footer />
      </ScrollingWrapper>

    );
}

export default Page
