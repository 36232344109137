import React from 'react'
import SectionTitle from './SectionTitle'

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';


import adp from '../images/partners/ADP_logo@2X-300x120.png'
import arconic from '../images/partners/Arconic_logo@2X-300x120.png'
import atlassian from '../images/partners/Atlassian_logo@2X-300x120.png'
import bnp from '../images/partners/BNP-Paribas_logo@2X-300x120.png'
import citi from '../images/partners/Citi_logo@2X-300x120.png'
import glowforge from '../images/partners/Glowforge_logo@2X-300x120.png'
import jetblue from '../images/partners/Jetblue_logo@2X-300x120.png'
import nycdoe from '../images/partners/NYC-DOE_logo@2X-300x120.png'
import osage from '../images/partners/Osage_logo@2X_v2-300x120.png'
import ragandbone from '../images/partners/partner01.png'
import venture from '../images/partners/partner02.png'
import salesForce from '../images/partners/partner06.png'
import nbcUniversal from '../images/partners/partner09.png'
import viacom from '../images/partners/partner10.png'
import ultimate from '../images/partners/Ultimate-Software_logo@2X-300x120.png'
import workhuman from '../images/partners/Workhuman_logo@2X-300x120.png'




const partners = [
    {
        image: adp,
        text: 'ADP',
    },
    {
        image: arconic,
        text: 'Aronic',
    },
    {
        image: atlassian,
        text: 'Atlassian',
    },
    {
        image: bnp,
        text: 'BNP',
    },
    {
        image: citi,
        text: 'Citi',
    },
    {
        image: glowforge,
        text: 'Glowforge',
    },
    {
        image: jetblue,
        text: 'Jetblue',
    },
    {
        image: nycdoe,
        text: 'NYC-DOE',
    },
    {
        image: osage,
        text: 'Osage',
    },
    {
        image: ragandbone,
        text: 'Rag and Bone',
    },
    {
        image: venture,
        text: 'Venture for America',
    },
    {
        image: salesForce,
        text: 'Sales Force',
    },
    {
        image: nbcUniversal,
        text: 'NBC Universal',
    },
    {
        image: viacom,
        text: 'Viacom',
    },
    {
        image: ultimate,
        text: 'Ultimate Software',
    },
    {
        image: workhuman,
        text: 'Workhuman',
    },

];





const Blog = () => {
    return (
        <div className="PartnersArea" id="partners">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <SectionTitle
                            title="Customers, Partners, & Investors"
                            text="At Translator we are proud to work with organizations of all shapes and sizes - and we have all one thing in common: we are committed to changing the world."
                        />
                    </div>
                    <Carousel
                      centerMode
                      // selectedItem={3}
                      centerSlidePercentage={40}
                      autoPlay
                      interval={3000}
                      infiniteLoop
                      showIndicators={false}
                      showThumbs={false}
                      showStatus={false}
                      >
                    {partners.map((featured, i) => (
                        <div key={i} className="partner">
                              <img src={featured.image} alt={featured.text} />
                        </div>
                    ))}
                  </Carousel>

                </div>
            </div>
        </div>
    )
}
export default Blog
