import React from 'react'
import { SocialIcon } from 'react-social-icons';

const sm = [
    {
        network: 'facebook'
,
        url: '',
    },
    {
        network: 'twitter'
,
        url: '',
    },
    {
        network: 'linkedin'
,
        url: '',
    },
    {
        network: 'instagram'
,
        url: '',
    },

];

const Footer = () => {
    return (
        <footer className="FooterArea">
            <div className="container">
                <p> Translator, Inc. © 2020, All Rights Reserved</p>
                <p className="iconLink">https://icons8.com</p>
            </div>

            <div>
              {sm.map((net, i) => (
                <SocialIcon key={i} url={net.url} network={net.network} bgColor="#5e731033" fgColor="#fff"style={{margin:'10px'}}/>

              ))}

            </div>
        </footer>
    )
};
export default Footer
