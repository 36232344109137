import React from 'react'
// import styled from 'styled-components'
import { Link} from 'react-scroll';
import close from '../images/icon/circle-up-icon.png'


class ScrollingWrapper extends React.Component {
state ={hasScrolled: false}


componentDidMount() {
  document.addEventListener("scroll", () => {
         if (window.scrollY > 120) {
              this.setState({ hasScrolled: true })
         } else {
             this.setState({ hasScrolled: false })
         }
      });
      window.scrollTo(0, 0);
}

  componentWillUnmount(){
    document.removeEventListener('scroll', this.handleScroll);
}

  render() {
    return (
      <React.Fragment>

        <div className={'ScrollingWrapperContainer'}>
          {this.props.children}
          {this.state.hasScrolled && (
            <Link
                smooth
                offset={0}
                duration={1000}
                to={'home'}
                className={'ScrollToTopIconContainer'}
            >
            <img src={close} width="50" alt="black circle arrow up icon" />
            </Link>

          )}
        </div>

      </React.Fragment>
    )
  }
}

export default ScrollingWrapper
