import React from 'react'
import SectionTitle from './SectionTitle'

// images
import feet from '../images/featured/feet.png'
import mask from '../images/featured/image10.png'
import spectrum from '../images/featured/image11.png'
import question from '../images/featured/question-mark.png'
import virus from '../images/featured/virus.png'
import popcorn from '../images/featured/popcorn.png'
const featureds = [
    {
        image: feet,
        title: 'Walk of Privilege',
        text: 'Empower participants to understand their privilege without shame and otherization.',
    },
    {
        image: mask,
        title: 'Stereotype Mask ',
        text: 'Help participants develop self-awareness through the lens of stereotypes and authenticity.',
    },
    {
        image: spectrum,
        title: 'The Spectrum',
        text: 'Give participants a new perspective and understanding of what makes us each unique.',
    },
    {
        image: popcorn,
        title: 'Word Popcorn!',
        text: 'Give participants a new perspective and understanding of what makes us each unique.',
    },
    {
        image: virus,
        title: 'Coronavirus Response',
        text: 'Understand how our emergency response highlights issues of inequity.',
    },
    {
        image: question,
        title: 'Design your own',
        text: 'Our module builder enables you to digitize any content.',
    },

];

const Featured = () => {
    return (
        <div className="AwesomeFeatureArea" id="features">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <SectionTitle
                            title="Features & Functionality"
                            text="Powerful D&I exercises can be delivered through in-person facilitation, Learning Management Software (LMS), embedded stand-alone modules, or direct email campaigns"
                        />
                    </div>
                    {featureds.map((featured, i) => (
                        <div key={i} className="col-lg-4 col-sm-6 col-12">
                            <div className="awesomeFeaturedWrap">
                                <div className="awesomeFeaturedIcon">
                                    <img src={featured.image} alt="" />
                                </div>
                                <h3>{featured.title}</h3>
                                <p>{featured.text}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    )
}
export default Featured
