import React from 'react'
import SectionTitle from './SectionTitle'
// images
import whoWeAre from '../../images/infoGraph.png';
// import whoWeAre from '../images/mockup/Image.png'
import shape1 from '../images/shape/shape1.svg'

const platformFeatures = [
    {
        number: 1,
        title: 'Employees interact',
        text:
            ' with software modules to learn and grow; while generating organizational insights.',
    },
    {
        number: 2,
        title: 'D&I leaders gain',
        text:
            ' new insights to measure, benchmark, and improve over time.',
    },
    {
        number: 3,
        title: 'Our platform',
        text:
            ' surfaces recommendations for policy change, programmatic content and system-level change.',
    },
];


const platform = () => {
    return (
        <div className="platformArea" id="platform">
            <div className="platformImg">
                <img src={whoWeAre} alt="" />
                <span className="shape1 poa zI-1 L-0 bounceAnimation">
                    <img src={shape1} alt="" />
                </span>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <SectionTitle
                            title="Our Impact"
                            // text="Translator is an anonymous Diversity & Inclusion experience platform for employees, and a rich culture analytics platform for management:"
                        />
                    </div>
                    <div className="col-lg-6 offset-lg-6">
                        <ul className="platformContetnt">
                            {platformFeatures.map((feature, i) => (
                                <li key={i}>
                                    <span>{feature.number}</span>
                                    <h4>
                                        <strong style={{fontSize: '30px'}}>{feature.title}</strong>
                                        {feature.text}
                                    </h4>

                                </li>
                            ))}

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default platform
