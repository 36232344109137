import React from 'react'
import {Link } from "react-router-dom";

const mainMenus = [{name:'home', url:'/'}]

const Header = () => {
    return (
        <header className="HeaderArea"  style={{background: 'linear-gradient(241.54deg, #b4b994 7.75%, #b4b994 70.83%)'}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="logo">
                          Translator
                          {  /* <img src={logo} alt="" /> */}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <ul className="Mainmenu">
                            {mainMenus.map(menu => (
                                <li key={menu.name}>
                                    <Link

                                        to={menu.url}
                                    >
                                        {menu.name}
                                    </Link>
                                </li>
                            ))}

                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}
export default Header
