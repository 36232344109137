import React, {Fragment} from 'react';
import {Grid} from "@material-ui/core";
import BreadCrumb from "../components/BreadCrumb";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";

import ErrorImage from '../images/techImage2.png';

import './style.scss';

const ErrorPage = () => {
    return (
        <Fragment>
          <div className={'content'}>

            <BreadCrumb
                title="Sorry, Page not found"
            />
            <Button component={Link} to="/" className="cBtn">BACK TO HOME PAGE</Button>

            <Grid className="">
                <img src={ErrorImage} alt="ErrorImage" style={{width: '65vw'}}/>
            </Grid>
          </div>
        </Fragment>
    )
};
export default ErrorPage;
