import React from 'react'

// import {Link} from "react-router-dom";
// images

// shape

// import { Link} from 'react-scroll';

const Hero = () => {
    return (
        <div className="FeaturedArea" id="home" style={{background: '#FBFBFF'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-12">

                    </div>
                </div>
            </div>
            <div className="heroImage">
            </div>
        </div>
    )
}
export default Hero
