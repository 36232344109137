import React from 'react';
import {Switch} from 'react-router-dom';

// Route Setting

import PrivateRoute from "../_PrivateRoute";
import PublicRoute from "../_PublicRoute";
import Page from '../_pages'
import News from '../_news'

import ErrorPage from "../ErrorPage";

export default function Routes() {
    return (
        <Switch>
            <PublicRoute
                exact
                path="/"
                comp={Page}
            />
            <PublicRoute
                exact
                path="/news"
                comp={News}
            />

            <PrivateRoute
                exact
                comp={ErrorPage}
            />
        </Switch>
    );
}
