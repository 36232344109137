import React from 'react'
import { Link} from 'react-scroll';

// images
// import logo from '../../images/logo2.svg'


const mainMenus = ['home', 'about', 'platform', 'features', 'news','contact', ]




class Header extends React.Component {
state ={hasScrolled: false}


componentDidMount() {
  document.addEventListener("scroll", () => {
         if (window.scrollY > 120) {
              this.setState({ hasScrolled: true })
         } else {
             this.setState({ hasScrolled: false })
         }
      });
      window.scrollTo(0, 0);
}

  componentWillUnmount(){
    document.removeEventListener('scroll', this.handleScroll);
}

  render() {
    return (
      <React.Fragment>

        <div className={'ScrollingWrapperContainer'}>
          {this.props.children}

            <header className= {`HeaderArea ${this.state.hasScrolled ? "scrolledHeader" : ""}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="logo">
                              Translator
                              {  /* <img src={logo} alt="" /> */}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <ul className="Mainmenu">
                                {mainMenus.map(menu => (
                                    <li key={menu}>
                                        <Link
                                            activeClass="active"
                                            spy
                                            smooth
                                            offset={0}
                                            duration={1000}
                                            to={menu}
                                        >
                                            {menu}
                                        </Link>
                                    </li>
                                ))}

                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </div>

      </React.Fragment>
    )
  }
}

export default Header
