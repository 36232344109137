import React from 'react'
import SectionTitle from './SectionTitle'
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";



const blogs = [
    {
        image: 'https://translator.company/wp-content/uploads/2019/05/translator_blogs-03-735x490.jpg',
        title: 'HOW THIS TRANSGENDER CEO CREATED SOFTWARE THAT FACILITATES EMPATHY',
        text: 'Why Fortune 500 companies are using with this software to facilitate difficult conversations.',
        link: 'https://www.fastcompany.com/90322404/how-this-transgender-ceo-created-software-that-facilitates-empathy?partner=newscred&utm_source=newscred&utm_medium=feed&utm_campaign=newscred+fastcompany&utm_content=newscred'
    },
    {
        image: 'https://translator.company/wp-content/uploads/2019/05/translator_blogs-02-762x508.jpg',
        title: 'FOUND IN TRANSLATION',
        text: 'Struggling with her identity, entrepreneur Natalie Egan defaulted to an aggressive leadership style that doomed her first company. Now,…',
        link: 'https://www.atlassian.com/blog/teamwork/found-in-translation'
    },
    {
        image: 'https://translator.company/wp-content/uploads/2019/05/translator_blogs-01-735x490.jpg',
        title: 'DIVERSITY & INCLUSION TECHNOLOGY: THE RISE OF A TRANSFORMATIVE MARKET',
        text: 'There is a renewed focus on diversity and inclusion (D&I) in the workplace and many companies are beginning to…',
        link: 'https://info.mercer.com/danditech'
    },

];

const Blog = () => {
    return (
        <div className="NewsArea" id="news">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <SectionTitle
                            title="The Latest From Translator"
                            // text="Check out the latest Translator news, blog posts, and curated D&I resources: "
                        />
                    </div>
                    {blogs.map((featured, i) => (
                        <div key={i} className="col-lg-4 col-sm-6 col-12">
                            <div className="awesomeFeaturedWrap">
                              <img src={featured.image} alt="" />
                              <h4>{featured.title}</h4>
                              <p>{featured.text}</p>
                              <a href={featured.link}>Read More</a>
                            </div>
                        </div>
                    ))}

                </div>
                <div className="more">
                  <Button component={Link} to="/news"  className="cBtn cBtnShadow mt-20 pr-50 pl-50">More</Button>
                </div>
            </div>
        </div>
    )
}
export default Blog
